import { api } from "@/instances/http";

class BonusTypesService {
  findMany(params) {
    return api.post("/bonus/types/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/bonus/types/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params) {
    return api.post("/bonus/types/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/bonus/types/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/bonus/types/create", { ...params }).then(response => {
      return response.data;
    });
  }
  delete(params) {
    return api.post("/bonus/types/delete", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new BonusTypesService();
