<template>
  <v-row>
    <v-col>
      <v-tabs v-model="tab">
        <v-tab>Создать промо</v-tab>
        <v-tab>Создать источник</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-title>Создание промо</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field label="Название" v-model="code" outlined />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Тип"
                    :items="this.types"
                    item-text="value"
                    item-value="id"
                    v-model="type"
                    outlined
                  />
                </v-col>
                <v-col v-if="type === 5" cols="12" sm="4">
                  <v-text-field label="Реферер" v-model="refererId" outlined />
                </v-col>
                <v-col v-if="type === 5" cols="12" sm="4">
                  <v-autocomplete
                    v-model="sourceId"
                    :items="sourceItems"
                    item-value="id"
                    item-text="value"
                    dense
                    label="Источник"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Сумма в рублях"
                    type="number"
                    v-model="amount"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Количество использований"
                    v-model="limit"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Минимальный депозит"
                    v-model="minDep"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Минимальный VIP"
                    v-model="minStatus"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="firstMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date_start"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_start"
                        label="Действует с"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_start" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="firstMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date_start)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu2"
                    v-model="lastMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date_expire"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_expire"
                        label="Действует до"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_expire" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="lastMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date_expire)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn color="primary" x-large outlined @click="createPromo"
                    >Создать Промо</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-title>Создание Источник</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Название"
                    v-model="sourceName"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Ссылка" v-model="sourceLink" outlined />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn color="primary" x-large outlined @click="createSource"
                    >Создать Источник</v-btn
                  >
                </v-col>
              </v-row>
              <v-data-table :items="sourceItems" :headers="sourceHeaders" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import BonusServices from "@/services/bonus.service";
import BonusTypesServices from "@/services/bonus.types.service";
import ADSSourceServices from "@/services/ads.source.service";
import { mapActions, mapGetters } from "vuex";

import moment from "moment";
export default {
  name: "CreatePromo",
  data: () => ({
    tab: 0,
    sourceId: 0,
    types: [],
    sourceName: "",
    sourceLink: "",
    sourceItems: [],
    sourceHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "value" },
      { text: "Ссылка", value: "description" }
    ],
    type: 3,
    code: "",
    amount: 0,
    limit: 0,
    minDep: 0,
    minStatus: 1,
    refererId: 0,
    firstMenu: false,
    lastMenu: false,
    date_start: new Date().toISOString().substr(0, 10),
    date_expire: moment()
      .add("1", "month")
      .toISOString()
      .substr(0, 10)
  }),
  computed: {
    ...mapGetters("user", ["user"])
  },
  methods: {
    ...mapActions("bonuses", ["findMany"]),
    createSource() {
      ADSSourceServices.create({
        data: {
          value: this.sourceName,
          description: this.sourceLink
        }
      }).then(
        () => {
          this.$noty.success("Успешно");
          this.loadSourses();
        },
        e => {
          this.$noty.error(e.response.message);
        }
      );
    },
    createPromo() {
      let params = {};
      if (this.refererId) {
        params.refererId = parseInt(this.refererId);
      }
      if (this.sourceId) {
        params.sourceId = this.sourceId;
      }
      BonusServices.create({
        data: {
          type: this.type,
          card_id: this.user.id,
          code: this.code,
          amount: parseInt(this.amount) * 100,
          limit: parseInt(this.limit),
          minDep: parseInt(this.minDep) * 100,
          minStatus: +this.minStatus,
          date_start: new Date(this.date_start),
          date_expire: new Date(this.date_expire),
          ...params
        }
      }).then(
        () => {
          this.findMany({
            orderBy: {
              id: "desc"
            },
            include: {
              source: true,
              cards: true
            },
            take: 15
          });
          this.$noty.success("Бонус успешно создан");
        },
        error => this.$noty.error(error.response.data.message)
      );
    },
    loadSourses() {
      ADSSourceServices.findMany().then(data => {
        this.sourceItems = data;
      });
    }
  },
  watch: {
    minStatus() {
      if (this.minStatus < 1) {
        this.minStatus = 1;
      } else if (this.minStatus > 10) {
        this.minStatus = 10;
      }
    }
  },
  created() {
    BonusTypesServices.findMany().then(data => {
      console.log(data);
      this.types = data;
    });
    this.loadSourses();
  }
};
</script>

<style scoped></style>
