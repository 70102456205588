import { api } from "@/instances/http";

class ADSSourceService {
  findMany(params) {
    return api.post("/ads/source/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/ads/source/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params) {
    return api.post("/ads/source/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/ads/source/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/ads/source/create", { ...params }).then(response => {
      return response.data;
    });
  }
  delete(params) {
    return api.post("/ads/source/delete", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new ADSSourceService();
