<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Бонусы</v-col>
    </v-row>
    <CreatePromo />
    <Bonuses />
  </v-container>
</template>

<script>
import CreatePromo from "@/views/pages/Bonus/CreatePromo";
import Bonuses from "@/views/pages/Bonus/Bonuses";
export default {
  name: "Index",
  components: {
    CreatePromo,
    Bonuses
  }
};
</script>

<style scoped></style>
